import React, { useEffect, useState } from "react";
import OfferingsCarousel from "./OfferingsCarousel";
import OfferingsDisplay from "./OfferingsDisplay";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet";

export default function Vitamins() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [offerings, setOfferings] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchOfferings = async () => {
      try {
        setOfferings(offerings);
        const response = await axios.get(
          `${process.env.REACT_APP_API}/offerings/vitamins`
        );
        setOfferings(response.data);
      } catch (e) {
        console.log(e);
        setOfferings(offerings);
      }
    };

    fetchOfferings();
  }, []);

  return (
    <div>
     <Helmet>
         <title>Multivitamins and Minerals Products by CARIN - CARIN Life </title>
        <meta name="title" content="Multivitamins and Minerals Products by CARIN - CARIN Life" />
        <meta name="description" content="Vitamins and minerals are essential components required in small amounts for normal functioning of the body. Checkout list of multivitamins and minerals offered by CARIN Life." />
        </Helmet>
      <section className="ls section_padding_top_25">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {" "}
              <img
                src={process.env.REACT_APP_IMAGES + "/vitamins.jpg"}
                alt=""
                className="alignleft offering_img"
              />
              <div
                className="to_animate section_padding_top_150"
                data-animation="fadeInLeft"
                data-delay="150"
              >
                <h3 className="section_header">Multivitamins and Minerals</h3>
                <p>
                  Vitamins and minerals are essential components required in
                  small amounts for normal functioning of the body.
                  Multivitamins and minerals (MVMs) tablets are the most
                  commonly used dietary supplements that contains recommended
                  levels of daily intake of most vitamins (A, C, D, E, and K,
                  and vitamin B-complex) and essential minerals (calcium,
                  phosphorus, sodium, chloride, magnesium, iron, zinc, iodine,
                  potassium etc.). People who don’t get enough vitamins and
                  minerals from diet (food) alone, or who have certain medical
                  conditions (deficiency disorders, pregnancy, digestive
                  disorders etc.) might benefit from taking Multivitamins and
                  minerals (MVMs) supplements [1].
                </p>
              </div>
            </div>

            <div
              className="col-sm-12 to_animate topmargin_40"
              data-animation="fadeInLeft"
              data-delay="150"
            >
              <h3 className="logo-color1 product_header">Products of Carin:</h3>
            </div>
          </div>
        </div>
      </section>

      <section
        id="products"
        className="offerings ds color parallax page_gallery columns_padding_25 to_animate"
        data-animation="fadeInLeft"
        data-delay="150"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Carousel
                showDots={false}
                responsive={responsive}
                transitionDuration={500}
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
              >
                {offerings.map((offering) => (
                  <OfferingsCarousel data={offering} />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>

      <section className="ls section_padding_bottom_65">
        <div className="container">
          <div className="row">
            {offerings.length &&
              offerings.map((offering, index) => {
                return <OfferingsDisplay data={offering} idx={index + 1} />;
              })}

            <div
              className="col-sm-12 topmargin_50 to_animate"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <h4 style={{ lineHeight: "32px" }} className="logo-color1">
                Recommended Dietary Allowance (RDA) of vitamins and minerals for
                Indians (ICMR-NIN 2020):
              </h4>
              <table className="color-black table table-striped diabetes-table col-sm-12 hor">
                <thead className="cardiac-table-color">
                  <tr>
                    <th style={{ color: "black" }} colspan="11">
                      <b>Vitamins</b>
                    </th>
                  </tr>
                </thead>
                <tbody className="cardiac-table-color">
                  <tr style={{ fontWeight: "bold" }} className="logo-color1">
                    <td></td>
                    <td>Vit B1</td>
                    <td>Vit B2</td>
                    <td>Vit B3</td>
                    <td>Vit B6</td>
                    <td>Vit B9</td>
                    <td>Vit B12</td>
                    <td>Vit C</td>
                    <td>Vit A</td>
                    <td>Vit D</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>(mg/d)</td>
                    <td>(mg/d)</td>
                    <td>(mg/d)</td>
                    <td>(mg/d)</td>
                    <td>(µg/d)</td>
                    <td>(µg/d)</td>
                    <td>(mg/d)</td>
                    <td>(µg/d)</td>
                    <td>(IU/d)</td>
                  </tr>
                  <tr>
                    <td>Men</td>
                    <td>1.4 - 2.3</td>
                    <td>2 - 3.2</td>
                    <td>14 - 23</td>
                    <td>1.9 - 3.1</td>
                    <td>300</td>
                    <td>2.2</td>
                    <td>80</td>
                    <td>1000</td>
                    <td>600</td>
                  </tr>
                  <tr>
                    <td>Women</td>
                    <td>1.4 - 2.2</td>
                    <td>1.9 - 3.1</td>
                    <td>11 - 18</td>
                    <td>1.9 - 2.4</td>
                    <td>220</td>
                    <td>2.2</td>
                    <td>65</td>
                    <td>840</td>
                    <td>600</td>
                  </tr>
                </tbody>
              </table>
              <table className="color-black table table-striped diabetes-table col-sm-12 ver">
                <thead className="cardiac-table-color">
                  <tr>
                    <th style={{ color: "black" }} colspan="4">
                      <b>Vitamins</b>
                    </th>
                  </tr>
                </thead>
                <tbody className="cardiac-table-color">
                  <tr style={{ fontWeight: "bold" }}>
                    <td></td>
                    <td></td>
                    <td>Men</td>
                    <td>Women</td>
                  </tr>
                  <tr>
                    <td className="logo-color1 bold">Vit B1</td>
                    <td> (mg/d) </td>
                    <td>1.4 - 2.3</td>
                    <td>1.4 - 2.2</td>
                  </tr>
                  <tr>
                    <td className="logo-color1 bold">Vit B2</td>
                    <td> (mg/d) </td>
                    <td>2 - 3.2</td>
                    <td>1.9 - 3.1</td>
                  </tr>
                  <tr>
                    <td className="logo-color1 bold">Vit B3</td>
                    <td> (mg/d) </td>
                    <td>14 - 23</td>
                    <td>11 - 18</td>
                  </tr>
                  <tr>
                    <td className="logo-color1 bold">Vit B6</td>
                    <td> (mg/d) </td>
                    <td>1.9 - 3.1</td>
                    <td>1.9 - 2.4</td>
                  </tr>
                  <tr>
                    <td className="logo-color1 bold">Vit B9</td>
                    <td> (µg/d) </td>
                    <td>300</td>
                    <td>220</td>
                  </tr>
                  <tr>
                    <td className="logo-color1 bold">Vit B12</td>
                    <td> (µg/d) </td>
                    <td>2.2</td>
                    <td>2.2</td>
                  </tr>
                  <tr>
                    <td className="logo-color1 bold">Vit C</td>
                    <td> (mg/d) </td>
                    <td>80</td>
                    <td>65</td>
                  </tr>
                  <tr>
                    <td className="logo-color1 bold">Vit A</td>
                    <td> (µg/d) </td>
                    <td>1000</td>
                    <td>840</td>
                  </tr>
                  <tr>
                    <td className="logo-color1 bold">Vit D</td>
                    <td> (IU/d) </td>
                    <td>600</td>
                    <td>600</td>
                  </tr>
                </tbody>
              </table>
              <table className="color-black table table-striped diabetes-table col-sm-12 hor">
                <thead className="cardiac-table-color">
                  <tr>
                    <th style={{ color: "black" }} colspan="6">
                      <b>Minerals</b>
                    </th>
                  </tr>
                </thead>
                <tbody className="cardiac-table-color">
                  <tr style={{ fontWeight: "bold" }} className="logo-color1">
                    <td></td>
                    <td>Ca</td>
                    <td>Mg</td>
                    <td>Fe</td>
                    <td>I</td>
                    <td>Zn</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>(mg/d)</td>
                    <td>(mg/d)</td>
                    <td>(mg/d)</td>
                    <td>(µg/d)</td>
                    <td>(mg/d)</td>
                  </tr>
                  <tr>
                    <td>Men</td>
                    <td>1000</td>
                    <td>440</td>
                    <td>19</td>
                    <td>150</td>
                    <td>17</td>
                  </tr>
                  <tr>
                    <td>Women</td>
                    <td>1000</td>
                    <td>370</td>
                    <td>29</td>
                    <td>150</td>
                    <td>13</td>
                  </tr>
                </tbody>
              </table>
              <table className="color-black table table-striped diabetes-table col-sm-12 ver">
                <thead className="cardiac-table-color">
                  <tr>
                    <th style={{ color: "black" }} colspan="4">
                      <b>Minerals</b>
                    </th>
                  </tr>
                </thead>
                <tbody className="cardiac-table-color">
                  <tr style={{ fontWeight: "bold" }}>
                    <td></td>
                    <td></td>
                    <td>Men</td>
                    <td>Women</td>
                  </tr>
                  <tr>
                    <td className="logo-color1 bold">Ca</td>
                    <td> (mg/d) </td>
                    <td>1000</td>
                    <td>1000</td>
                  </tr>
                  <tr>
                    <td className="logo-color1 bold">Mg</td>
                    <td> (mg/d) </td>
                    <td>440</td>
                    <td>370</td>
                  </tr>
                  <tr>
                    <td className="logo-color1 bold">Fe</td>
                    <td> (mg/d) </td>
                    <td>19</td>
                    <td>29</td>
                  </tr>
                  <tr>
                    <td className="logo-color1 bold">I</td>
                    <td> (µg/d) </td>
                    <td>150</td>
                    <td>150</td>
                  </tr>
                  <tr>
                    <td className="logo-color1 bold">Zn</td>
                    <td> (mg/d) </td>
                    <td>17</td>
                    <td>13</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="col-sm-12 topmargin_30 to_animate"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <div id="accordion3" className="panel-group collapse-unstyled">
                <div className="panel">
                  <h4 className="poppins logo-color1">
                    {" "}
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#collapse11"
                    >
                      References:
                    </a>{" "}
                  </h4>
                  <div id="collapse11" className="panel-collapse collapse">
                    <div className="panel-content">
                      <ol className="list1 no-bullets">
                        <li>
                          Hathcock J. Vitamins and minerals: efficacy and
                          safety. The American journal of clinical nutrition.
                          1997;66(2):427-37.
                        </li>
                        <li>
                          Huang H-Y, Caballero B, Chang S, Alberg AJ, Semba RD,
                          Schneyer C, et al. Multivitamin/mineral supplements
                          and prevention of chronic disease: executive summary.
                          The American journal of clinical nutrition.
                          2007;85(1):265S-8S.
                        </li>
                        <li>
                          Jayaram S, Khobragade A, Langade D. Methylcobalamin,
                          Pyridoxine and Nicotinamide in Diabetic Neuropathy: A
                          Review. Indian Journal of Clinical Practice.
                          2009;20(1).
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
