import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import firebase from 'firebase';
import AdminNavbar from './Admin/AdminNavbar';
import checkAuth from '../Utility/ApiService';

export default function PrivateRoute({ component: Component, ...rest }) {

  const [flag, setFlag] = React.useState(true);
  const location = useLocation();

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // console.log(user);
        user.getIdToken().then(function(idToken) {
          var mobile = user.phoneNumber.substring(3);
          checkAuth(mobile, idToken)
          .then((res) => {
            // console.log(res.data.data.user);
            if(res.data.data.user.admin && res.data.data.user.enabled){
              console.log(location.pathname);
              setFlag(true);
            }
            else {
              console.log("No longer an admin");
              setFlag(false);
            }
          });
        })
      } else {
        // User is signed out
        console.log("Not signed in");
        setFlag(false);
      }
    });
  }, []);

  return (
    <Route
      {...rest}
      render={props => {
        return flag ? <div> <AdminNavbar /><Component {...props} /> </div> : <Redirect to="/admin/login" />
      }}
    ></Route>
  )
}
