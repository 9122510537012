import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { auth } from "../../firebase";
import firebase from "firebase/app";
import "firebase/auth";
import checkAuth from "./../../Utility/ApiService";

export default function Login() {
  const mobileRef = useRef();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then(function (idToken) {
          var mobile = user.phoneNumber.substring(3);
          checkAuth(mobile, idToken).then((res) => {
            // console.log(res.data.data.user);
            if (res.data.data.user.admin && res.data.data.user.enabled) {
              history.push("/admin/home");
            } else console.log("Not signed in");
          });
        });
      } else {
        // User is signed out
        console.log("Not signed in");
      }
    });
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setLoading(true);
      if (error === "") {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: function (response) {},
          }
        );
      }
      setError("");

      const appVerifier = window.recaptchaVerifier;

      auth
        .signInWithPhoneNumber("+91" + mobileRef.current.value, appVerifier)
        .then(function (confirmationResult) {
          // var cod = window.prompt('Please enter the 6 digit code now');
          var code = window.prompt("Please enter the 6 digit code");
          console.log(code);
          if (code === "") setError("Empty verification code");
          confirmationResult
            .confirm(code)
            .then((result) => {
              result.user
                .getIdToken()
                .then(function (idToken) {
                  checkAuth(mobileRef.current.value, idToken).then((res) => {
                    if (
                      res.data.data.user.admin &&
                      res.data.data.user.enabled
                    ) {
                      history.push("/admin/home");
                    } else {
                      setError("You are not an admin!");
                      auth.signOut();
                      // setTimeout(() => {
                      //   console.log("inside timeout");
                      // }, 3000);
                    }
                  });
                })
                .catch((err) => {
                  history.push("/admin/login");
                });
            })
            .catch((err) => {
              console.log(err);
              setError(err.message);
              // throw new Error( `${err.message}` );
            });
        })
        .catch((err) => {
          setError(err.message);
        });
    } catch {
      setError("Failed to log in");
    }

    setLoading(false);
  }

  return (
    <div className="container" style={{ marginTop: "100px" }}>
      {loading ? (
        "Loading..."
      ) : (
        <div className="col-sm-4 col-sm-offset-4">
          <h2 style={{ color: "black" }}>
            <span className="fa fa-sign-in"></span> Login
          </h2>
          <form id="login">
            <div className="form-group">
              <label style={{ fontWeight: "bold" }}>Mobile</label>
              <input
                type="text"
                className="form-control"
                name="mobile"
                ref={mobileRef}
              />
            </div>
            <button
              onClick={handleSubmit}
              type="submit"
              name="login"
              className="theme_button color1 inverse"
            >
              Login
            </button>
          </form>
          <br />
          <div id="recaptcha-container"></div>
          <p class="login-err">{error}</p>
          <hr />
          <p>
            Or go back to <a href="/">starting screen</a>.
          </p>
          <br />
          <br />
          <div className="text-center">
            <p>
              © Copyright 2021. All Rights Reserved. <a href="/">Carin</a>
            </p>
            <p>
              Visit our <a href="/"> site</a>.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
