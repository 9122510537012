import React, { useEffect, useState } from "react";
import OfferingsCarousel from "./OfferingsCarousel";
import OfferingsDisplay from "./OfferingsDisplay";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet";

export default function Gastrology() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [offerings, setOfferings] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchOfferings = async () => {
      try {
        setOfferings(offerings);
        const response = await axios.get(
          `${process.env.REACT_APP_API}/offerings/gastrology`
        );
        setOfferings(response.data);
      } catch (e) {
        console.log(e);
        setOfferings(offerings);
      }
    };

    fetchOfferings();
  }, []);

  return (
    <div>
     <Helmet>
         <title>Healthcare Products for Gastroesophageal reflux diseases (GERD) - CARIN Life </title>
        <meta name="title" content="Healthcare Products for Gastroesophageal reflux diseases (GERD) - CARIN Life" />
        <meta name="description" content="Gastroesophageal reflux disease damages the inner lining of esophagus and causes heartburn. Checkout products offered by CARIN Life for GERD." />
        </Helmet>
      <section className="ls section_padding_top_25">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {" "}
              <img
                src={process.env.REACT_APP_IMAGES + "/gastro.jpg"}
                alt=""
                className="alignleft offering_img"
              />
              <div
                className="to_animate section_padding_top_150"
                data-animation="fadeInLeft"
                data-delay="150"
              >
                <h3 className="section_header">
                  Gastroesophageal reflux diseases (GERD)
                </h3>
                <p>
                  Gastroesophageal reflux disease (GERD) is a chronic disease in
                  which the acidified liquid contents of the stomach refluxed
                  back into the esophagus, damaging the inner lining and causing
                  heartburn, dysphagia, chest pain, and nausea [1]. Globally,
                  GERD is highly prevalent (about 10–20%) and significantly
                  impacts on the quality of life. The prevalence of GERD in
                  India was nearly equivalent to that in the West and is higher
                  than in several Asian countries [2]. GERD doesn’t cause severe
                  complications; however, if left untreated it may lead to
                  severe or even life-threatening health conditions. Potential
                  complications of GERD are esophagitis, esophageal ulcer,
                  Barrett’s esophagus, chronic cough or asthma, dental erosions,
                  and even esophageal cancer.
                </p>
              </div>
            </div>

            <div
              className="col-sm-12 to_animate topmargin_40"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <h3 className="product_header logo-color1">Products of Carin:</h3>
            </div>
          </div>
        </div>
      </section>

      <section
        id="products"
        className="offerings ds color parallax page_gallery columns_padding_25 to_animate"
        data-animation="fadeInLeft"
        data-delay="150"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Carousel
                showDots={false}
                responsive={responsive}
                transitionDuration={500}
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
              >
                {offerings.map((offering) => (
                  <OfferingsCarousel data={offering} />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>

      <section className="ls section_padding_bottom_65">
        <div className="container">
          <div className="row">
            {offerings.length &&
              offerings.map((offering, index) => {
                return <OfferingsDisplay data={offering} idx={index + 1} />;
              })}
            <div
              className="col-sm-12 topmargin_30 to_animate"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <br />
              <h4 style={{ lineHeight: "32px" }} className="logo-color1">
                Management of Gastroesophageal reflux diseases (GERD)
              </h4>
              <h5 style={{ fontWeight: 500 }} className="logo-color2">
                Lifestyle Adjustments:
              </h5>
              <p>
                Avoid foods and beverages that relaxing the lower esophageal
                sphincter (LES) and allow Gastric acid from stomach refluxed
                back into the esophagus and trigger heartburn symptoms or
                irritate the esophagus.
              </p>
              <h5 style={{ fontWeight: 500 }} className="logo-color2">
                Medications:
              </h5>
              <p>
                However, if lifestyle adjustments aren't enough to prevent and
                relieve symptoms of GERD then doctor may recommend medications
                like antacids, H2 receptor blockers, proton pump inhibitors
                (PPIs), and prokinetics.
              </p>
            </div>
            <div
              className="col-sm-12 topmargin_30 to_animate"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <div id="accordion3" className="panel-group collapse-unstyled">
                <div className="panel">
                  <h4 className="poppins logo-color1">
                    {" "}
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#collapse11"
                    >
                      References:
                    </a>{" "}
                  </h4>
                  <div id="collapse11" className="panel-collapse collapse">
                    <div className="panel-content">
                      <ol className="list1 no-bullets">
                        <li>
                          Antunes C, Aleem A, Curtis SA. Gastroesophageal Reflux
                          Disease. StatPearls [Internet]. 2020.
                        </li>
                        <li>
                          Bhatia SJ, Makharia GK, Abraham P, Bhat N, Kumar A,
                          Reddy DN, et al. Indian consensus on gastroesophageal
                          reflux disease in adults: a position statement of the
                          Indian Society of Gastroenterology. Indian Journal of
                          Gastroenterology. 2019;38(5):411-40.
                        </li>
                        <li>
                          Dabrowski A, Štabuc B, Lazebnik L. Meta-analysis of
                          the efficacy and safety of pantoprazole in the
                          treatment and symptom relief of patients with
                          gastroesophageal reflux disease–PAN-STAR. Przeglad
                          gastroenterologiczny. 2018;13(1):6.
                        </li>
                        <li>
                          Shahani S, Sawant P, Dabholkar P. Rabeprazole plus
                          domperidone: the answer for gastro-oesophageal reflux
                          disease. Journal of the Indian Medical Association.
                          2008;106(4):264, 6, 8-, 6, 8.
                        </li>
                        <li>
                          Maiti R, Jaida J, Israel PJ, Koyagura N, Mukkisa S,
                          Palani A. Rabeprazole and esomeprazole in
                          mild-to-moderate erosive gastroesophageal reflux
                          disease: A comparative study of efficacy and safety.
                          Journal of pharmacology & pharmacotherapeutics.
                          2011;2(3):150.
                        </li>
                      </ol>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
