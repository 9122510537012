import React, { useEffect, useState } from "react";
import OfferingsCarousel from "./OfferingsCarousel";
import OfferingsDisplay from "./OfferingsDisplay";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet";

export default function Gynaecology() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [offerings, setOfferings] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchOfferings = async () => {
      try {
        setOfferings(offerings);
        const response = await axios.get(
          `${process.env.REACT_APP_API}/offerings/dermatology`
        );
        setOfferings(response.data);
      } catch (e) {
        console.log(e);
        setOfferings(offerings);
      }
    };

    fetchOfferings();
  }, []);

  return (
    <div>
    <Helmet>
         <title>Dermatology: Healthcare Products for Skin Diseases - CARIN Life </title>
        <meta name="title" content="Dermatology: Healthcare Products for Skin Diseases - CARIN Life" />
        <meta name="description" content="Skin diseases are mainly caused by pathogens like bacteria, fungi, viruses etc. Checkout products offered by CARIN Life for various skin diseases." />
        </Helmet>
      <section className="ls section_padding_top_25">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {" "}
              <img
                src={process.env.REACT_APP_IMAGES + "/derma.jpg"}
                alt=""
                className="alignleft offering_img"
              />
              <div
                className="to_animate section_padding_top_150 section"
                data-animation="fadeInLeft"
                data-delay="150"
              >
                <h3 className="section_header">Dermatology</h3>
                <p>
                  Skin diseases are among the most common diseases in the
                  general population. Skin diseases are mainly caused by
                  pathogens like bacteria, fungi, viruses etc. Skin disorders
                  include a diverse range of symptoms that varies from minor to
                  life threatening. Common Skin diseases are eczema, rash, acne,
                  ringworm, chickenpox, warts, hives, Contact dermatitis,
                  Measles, Cellulitis, Psoriasis, Rosacea etc. The clinical
                  presentation of skin diseases also varies based on the type of
                  pathogen and the underlying medical conditions. WHO reported a
                  high prevalence (21-87%) of skin disorders in the general
                  population of developing countries. The prevalence of skin
                  diseases in India varies from 7.9% to 60% [1].
                </p>
              </div>
              <br />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div
              className="col-sm-12 to_animate"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <h3 className="logo-color1 product_header">Products of Carin:</h3>
            </div>
          </div>
        </div>
      </section>

      <section
        id="products"
        className="offerings ds color parallax page_gallery columns_padding_25 to_animate"
        data-animation="fadeInLeft"
        data-delay="150"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Carousel
                showDots={false}
                responsive={responsive}
                transitionDuration={500}
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
              >
                {offerings.map((offering) => (
                  <OfferingsCarousel data={offering} />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>

      <section className="ls section_padding_bottom_65">
        <div className="container">
          <div className="row">
            {offerings.length &&
              offerings.map((offering, index) => {
                return <OfferingsDisplay data={offering} idx={index + 1} />;
              })}
            <br />
            <br />
            <div
              style={{ marginTop: "30px" }}
              className="col-sm-12 to_animate"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <h4 className="logo-color1">Management of Skin diseases:</h4>
              <h5 className="txt-bold logo-color2">Lifestyle modifications:</h5>
              <p>
                Lifestyle adjustments like good hygiene practices, dietary
                changes, avoid physical stress may help to prevent certain skin
                diseases.{" "}
              </p>
              <h5 className="txt-bold logo-color2">Medications:</h5>
              <p>
                Different classes of medications commonly used for treatments of
                skin diseases includes antifungal or antibacterial creams or
                ointments, antibiotics, antihistamines, vitamin, and steroid.
              </p>
            </div>
            <div
              className="col-sm-12 to_animate"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <div id="accordion3" className="panel-group collapse-unstyled">
                <div className="panel">
                  <br />
                  <h4 className="poppins logo-color1">
                    {" "}
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#collapse11"
                    >
                      References:
                    </a>
                  </h4>
                  <div id="collapse11" className="panel-collapse collapse">
                    <div className="panel-content">
                      <ol className="list1 no-bullets">
                        <li>
                          Singhal RR, Talati KN, Gandhi BP, Shinde MK, Nair PA,
                          Phatak AG. Prevalence and pattern of skin diseases in
                          tribal villages of Gujarat: A teledermatology
                          approach. Indian Journal of Community Medicine:
                          Official Publication of Indian Association of
                          Preventive & Social Medicine. 2020;45(2):199.
                        </li>
                        <li>
                          Martin MV. The use of fluconazole and itraconazole in
                          the treatment of Candida albicans infections: a
                          review. Journal of Antimicrobial Chemotherapy.
                          1999;44(4):429-37.
                        </li>
                        <li>
                          Khanna D, Bharti S. Luliconazole for the treatment of
                          fungal infections: an evidence-based review. Core
                          evidence. 2014;9:113.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
