import React, { useEffect, useState } from "react";
import OfferingsCarousel from "./OfferingsCarousel";
import OfferingsDisplay from "./OfferingsDisplay";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet";

export default function Gynaecology() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [offerings, setOfferings] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchOfferings = async () => {
      try {
        setOfferings(offerings);
        const response = await axios.get(
          `${process.env.REACT_APP_API}/offerings/gynaecology`
        );
        setOfferings(response.data);
      } catch (e) {
        console.log(e);
        setOfferings(offerings);
      }
    };

    fetchOfferings();
  }, []);

  return (
    <div>
    <Helmet>
         <title>Healthcare Products for Gynaecology and Obstetrics - CARIN Life </title>
        <meta name="title" content="Healthcare Products for Gynaecology and Obstetrics - CARIN Life" />
        <meta name="description" content="Women in early pregnancy are susceptible to nutrient deficiencies and likely to develop complications. Checkout products offered by CARIN Life for Gynaecology and Obstetrics." />
        </Helmet>
      <section className="ls section_padding_top_25">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {" "}
              <img
                src={process.env.REACT_APP_IMAGES + "/gynaecology.jpg"}
                alt=""
                className="alignleft offering_img"
              />
              <div
                className="to_animate section_padding_top_150"
                data-animation="fadeInLeft"
                data-delay="150"
              >
                <h3 className="section_header">Gynaecology and Obstetrics</h3>
                <p>
                  Women in early pregnancy are susceptible to nutrient
                  deficiencies and likely to develop complications like anemia,
                  preeclampsia and many other pregnancy related conditions.
                  Therefore, macro and micronutrients supplements especially
                  protein, iron, folic acid, calcium and vitamins are
                  recommended for pregnant women to reduce nutrient
                  deficiencies. Iron deficiency may cause nutritional anemia,
                  according to the National Family Health Survey-4 (NFHS-4)
                  50.3% of the pregnant women had anemia in India [1]. Globally,
                  neonatal deaths account for two-thirds of deaths in infancy
                  and about 40% of child died before age five years [2].
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div
              className="col-sm-12 to_animate"
              data-animation="fadeInLeft"
              data-delay="150"
            >
              <h3 className="logo-color1 product_header">Products of Carin:</h3>
            </div>
          </div>
        </div>
      </section>
      <section
        id="products"
        className="offerings ds color parallax page_gallery columns_padding_25 to_animate"
        data-animation="fadeInLeft"
        data-delay="150"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Carousel
                showDots={false}
                responsive={responsive}
                transitionDuration={500}
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
              >
                {offerings.map((offering) => (
                  <OfferingsCarousel data={offering} />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
      <section className="ls section_padding_bottom_75">
        <div className="container">
          <div className="row">
            {offerings.length &&
              offerings.map((offering, index) => {
                return <OfferingsDisplay data={offering} idx={index + 1} />;
              })}
            <div
              style={{ paddingTop: "50px" }}
              className="col-sm-12 to_animate"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <div id="accordion3" className="panel-group collapse-unstyled">
                <div className="panel">
                  <h4 className="poppins logo-color1">
                    {" "}
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#collapse11"
                    >
                      References:{" "}
                    </a>{" "}
                  </h4>
                  <div id="collapse11" className="panel-collapse collapse">
                    <div className="panel-content">
                      {" "}
                      <ol className="list1 no-bullets">
                        <li>
                          Srivastava R, Kant S, Singh AK, Saxena R, Yadav K,
                          Pandav CS. Effect of iron and folic acid tablet versus
                          capsule formulation on treatment compliance and iron
                          status among pregnant women: a randomized controlled
                          trial. Journal of Family Medicine and Primary Care.
                          2019;8(2):378.
                        </li>
                        <li>
                          Costello AMdL, Osrin D. Micronutrient status during
                          pregnancy and outcomes for newborn infants in
                          developing countries. The Journal of nutrition.
                          2003;133(5):1757S-64S.
                        </li>
                        <li>
                          Malik S, Krishnaprasad K. Natural Micronized
                          Progesterone Sustained Release (SR) and Luteal Phase:
                          Role Redefined!! Journal of clinical and diagnostic
                          research: JCDR. 2016;10(2):QE01.
                        </li>
                        <li>
                          Coomarasamy A, Devall AJ, Brosens JJ, Quenby S,
                          Stephenson MD, Sierra S, et al. Micronized vaginal
                          progesterone to prevent miscarriage: a critical
                          evaluation of randomized evidence. American journal of
                          obstetrics and gynecology. 2020.
                        </li>
                        <li>
                          Greenberg JA, Bell SJ, Van Ausdal W. Omega-3 fatty
                          acid supplementation during pregnancy. Reviews in
                          obstetrics and Gynecology. 2008;1(4):162.
                        </li>
                        <li>
                          De‐Regil LM, Palacios C, Lombardo LK, Peña‐Rosas JP.
                          Vitamin D supplementation for women during pregnancy.
                          Cochrane database of systematic reviews. 2016(1).
                        </li>
                        <li>
                          Crowley P, Gallagher H. Clotrimazole as a
                          pharmaceutical: past, present and future. Journal of
                          applied microbiology. 2014;117(3):611-7.
                        </li>
                      </ol>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
