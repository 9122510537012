import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { FooterProducts } from "./FooterProducts";
import { useStyles } from "./ProductsStyles";
import { DialogTitle, DialogContent, DialogActions } from "./ProductsDialog";
import "./Products.css";

export default function Products(props) {
  const classes = useStyles();

  let { product_name } = props.match.params;

  const history = useHistory();

  const mediaMatch = window.matchMedia("(min-width: 765px)");
  const [matches, setMatches] = useState(mediaMatch.matches);
  const [open, setOpen] = useState(false);
  const [fopen, setFopen] = useState(false);
  const [mopen, setMopen] = useState(false);
  const [product, setProduct] = useState([]);
  const [allProduct, setAllProduct] = useState([]);
  const [tab, setTab] = useState();
  const [title, setTitle] = useState();
  // const [vidURL, setVidURL] = useState();
  const scroll = "paper";
  // const [vopen, setVopen] = useState(false);

  const handleClickOpen = (e) => {
    setTab(e.target.id);
    let title = e.target.className.split(" ");
    title.shift();
    setTitle(title.join(" "));
    setOpen(true);
  };

  const handleClickFopen = (e) => {
    setTab(e.target.id);
    let title = e.target.className.split(" ");
    title.shift();
    setTitle(title.join(" "));
    setFopen(true);
  };

  const handleClickMopen = (e) => {
    setMopen(true);
  };

  // const handleVidOpen = (e) => {
  //     console.log(e.target.id);
  //     setVidURL(e.target.id);
  //     // setVopen(true);
  //     // setWopen(false);
  // };

  const handleClose = () => {
    setOpen(false);
    setFopen(false);
    setMopen(false);
    // setVopen(false)
  };

  const handleBatch = (ele) => {
    for (let i = 0; i < allProduct.length; i++) {
      if (allProduct[i].batch_no === ele.target.value) {
        setProduct(allProduct[i]);
      }
    }
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setProduct(product);
        const response = await axios.get(
          `${process.env.REACT_APP_API}/products/${product_name}`
        );

        setAllProduct(response.data);
        setProduct(response.data[0]);
      } catch (e) {
        setProduct(product);
        history.push("/404");
      }
    };

    fetchProduct();
    // imageMapResize();

    const handler = (e) => setMatches(e.matches);
    mediaMatch.addEventListener("clicl", handler);
    return () => mediaMatch.removeEventListener("click", handler);
  }, []);

  return (
    <div>
      <section className="ls section_padding_bottom_30 columns_padding_25">
        <div className="container">
          <div
            style={{
              position: "relative",
              textAlign: "center",
            }}
            className="row"
          >
            <img
              src={product.img}
              useMap="#image-map"
              style={{ zIndex: 0 }}
              alt="product"
            />
            <div>
              <div
                className={
                  matches
                    ? "indications-true Indications"
                    : "indications-false Indications"
                }
                style={{ cursor: "pointer" }}
                alt="indications"
                id="indications"
                onClick={handleClickOpen}
              ></div>
              <div
                className={
                  matches ? "dosage-true Dosage" : "dosage-false Dosage"
                }
                alt="dosage"
                id="dosage"
                style={{ cursor: "pointer" }}
                onClick={handleClickOpen}
              ></div>
              <div
                className={
                  matches
                    ? "action-true Mode of Action"
                    : "action-false Mode of Action"
                }
                alt="action"
                id="mode_action"
                style={{ cursor: "pointer" }}
                onClick={handleClickOpen}
              ></div>
              <div
                className={matches ? "FAQ-true FAQs" : "FAQ-false FAQs"}
                alt="FAQ"
                style={{ cursor: "pointer" }}
                id="FAQ_ques"
                onClick={handleClickFopen}
              ></div>
              <div
                className={
                  matches
                    ? "side_effects-true Side Effects"
                    : "side_effects-false Side Effects"
                }
                alt="side_effects"
                id="side_effects"
                style={{ cursor: "pointer" }}
                onClick={handleClickOpen}
              ></div>
              <div
                className={
                  matches
                    ? "interaction-true Interaction"
                    : "interaction-false Interaction"
                }
                alt="interaction"
                id="interaction"
                style={{ cursor: "pointer" }}
                onClick={handleClickOpen}
              ></div>
              <div
                className={
                  matches
                    ? "safety_advice-true Safety Advice"
                    : "safety_advice-false Safety Advice"
                }
                alt="safety_advice"
                id="safety"
                style={{ cursor: "pointer" }}
                onClick={handleClickOpen}
              ></div>
            </div>

            {open && (
              <div className="alignment-center">
                <br />
                <Dialog
                  onClose={handleClose}
                  aria-labelledby="customized-dialog-title"
                  fullWidth
                  open={open}
                >
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                  >
                    <span style={{ fontSize: "18px" }}>{title}</span>
                  </DialogTitle>
                  <DialogContent dividers>
                    <Typography gutterBottom>
                      {product[tab] !== "" && product[tab] !== null ? (
                        product[tab].split("&").length !== 1 ? (
                          <ul
                            style={{ marginBottom: "5px" }}
                            className="list2 color2 checklist grey"
                          >
                            {product[tab].split("&").map(function (point, idx) {
                              return (
                                <li className="carin_products" key={idx}>
                                  {" "}
                                  {point.trim()}{" "}
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          <p className="carin_products">
                            {product[tab].trim()}
                          </p>
                        )
                      ) : (
                        <p className="carin_products">Loading ...</p>
                      )}
                    </Typography>
                  </DialogContent>
                  <DialogActions></DialogActions>
                </Dialog>
              </div>
            )}
            {fopen && (
              <div style={{ textAlign: "center" }}>
                <br />
                <Dialog
                  onClose={handleClose}
                  aria-labelledby="customized-dialog-title"
                  scroll={scroll}
                  fullWidth
                  open={fopen}
                >
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                  >
                    <span style={{ fontSize: "18px" }}>{title}</span>
                  </DialogTitle>
                  <DialogContent dividers>
                    <Typography gutterBottom>
                      {product[tab] !== "" && product[tab] !== null ? (
                        product[tab].split("&").map(function (point, idx) {
                          return (
                            <div>
                              <p key={idx} className="carin_products txt_bold">
                                {" "}
                                Q. {point.trim()}{" "}
                              </p>{" "}
                              <p
                                key={idx + product[tab].split("&").length}
                                className="carin_products"
                              >
                                {" "}
                                A. {product["FAQ_ans"].split("&")[idx].trim()}
                              </p>
                              <br />
                            </div>
                          );
                        })
                      ) : (
                        <p className="carin_products">Loading ...</p>
                      )}
                    </Typography>
                  </DialogContent>
                  <DialogActions></DialogActions>
                </Dialog>
              </div>
            )}
          </div>

          <div className="row">
            <div className="txt_bold color-black select-batch">
              <div>
                <select onChange={handleBatch}>
                  {allProduct.map((list) => {
                    return (
                      <option value={list.batch_no} key={list.id}>
                        BATCH NO: {list.batch_no}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className="row" style={{ padding: "20px 0 12px" }}>
            <Grid container className={classes.root} spacing={1}>
              <Grid item xs={12}>
                <Grid container justify="center" spacing={0}>
                  {product.quality_reports !== "" &&
                    product.quality_reports !== null && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${process.env.REACT_APP_QA_REPORT}/${product.quality_reports}`}
                        alt="work"
                      >
                        <Grid style={{ textAlign: "center" }} key={1} item>
                          <div className={`${classes.paper} batch-img`}>
                            <img src="/img/reports.svg" alt="option" />
                          </div>
                          <div className="product-details">
                            Quality
                            <br />
                            Reports
                          </div>
                        </Grid>
                      </a>
                    )}
                  {product.patient_info_leaflets !== "" &&
                    product.patient_info_leaflets !== null && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/pdf/patient_info_leaflets/${product.patient_info_leaflets}.pdf`}
                        alt="work"
                      >
                        <Grid style={{ textAlign: "center" }} key={2} item>
                          <div className={classes.paper}>
                            <img src="/img/leaflet.svg" alt="option" />
                          </div>
                          <div className="product-details">
                            Patient Info
                            <br />
                            Leaflet
                          </div>
                        </Grid>
                      </a>
                    )}
                  {product.manufactured_by !== "" &&
                    product.manufactured_by !== null && (
                      <Grid
                        onClick={handleClickMopen}
                        style={{ textAlign: "center" }}
                        key={3}
                        item
                      >
                        <div className={`${classes.paper} batch-img`}>
                          <img src="/img/manufactured.svg" alt="option" />
                        </div>
                        <div className="product-details">
                          Manufactured
                          <br />
                          By
                        </div>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
            {mopen && (
              <div style={{ textAlign: "center" }}>
                <br />
                <Dialog
                  onClose={handleClose}
                  aria-labelledby="customized-dialog-title"
                  scroll={scroll}
                  fullWidth
                  open={mopen}
                >
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                  >
                    <span style={{ fontSize: "16px" }}>Manufactured By</span>
                  </DialogTitle>
                  <DialogContent dividers>
                    <Typography gutterBottom>
                      {product.manufactured_by !== "" &&
                        product.manufactured_by !== null && (
                          <div>
                            <div
                              className="txt_bold line-height--20"
                              style={{ marginBottom: "5px", fontSize: "14px" }}
                            >
                              {
                                product.manufactured_by
                                  .split("?")[0]
                                  .split("^")[0]
                              }{" "}
                              {product.manufactured_by.split("?")[0].split("^")
                                .length === 2 && (
                                <p
                                  className="line-height--16"
                                  style={{
                                    fontSize: "12px",
                                    marginBottom: "2px",
                                  }}
                                >
                                  {
                                    product.manufactured_by
                                      .split("?")[0]
                                      .split("^")[1]
                                  }
                                </p>
                              )}{" "}
                            </div>{" "}
                            <p
                              className="line-height--16"
                              style={{ fontSize: "12px", marginBottom: "15px" }}
                            >
                              {product.manufactured_by.split("?")[1]}
                            </p>
                          </div>
                        )}
                    </Typography>
                  </DialogContent>
                  <DialogActions></DialogActions>
                </Dialog>
              </div>
            )}
          </div>

          <div className="row">
            <div
              className="medium alignment-center"
              style={{ marginTop: "2rem" }}
            >
              <span
                style={{ marginBottom: 0, fontSize: "14px", color: "black" }}
                className="line-height--14"
              >
                Disclaimer:
              </span>
              <p className="line-height--14" style={{ fontSize: "12px" }}>
                Please consult your physician for personalized medical advice.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="cs logo-gradient" style={{ padding: "25px 0 90px" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div
                className="owl-carousel partners-carousel"
                data-responsive-lg="9"
                data-responsive-md="6"
                data-responsive-sm="4"
                data-responsive-xs="2"
                data-nav="true"
                data-dots="false"
                data-center="true"
                data-loop="true"
                style={{ textAlign: "center" }}
              >
                {FooterProducts.map((prod, index) => {
                  return (
                    <a key={"footer-products-" + index} href="#!">
                      <img
                        style={{ height: prod.style ? prod.style.height : "" }}
                        alt="product"
                        src={prod.src}
                      />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* https://wa.me/message/S2BZUWA3WW4MC1 */}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://wa.me/message/S2BZUWA3WW4MC1`}
          >
            <Fab aria-label="add" className="fabButton">
              <AddIcon style={{ fontSize: "30px", color: "#4b4495" }} />
            </Fab>
          </a>

          <div className={classes.grow}>SET DIGITAL REMINDER</div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
