export const FooterProducts = [
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/aceparin SP.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/aceparin.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Bfolin.svg",
    style: { height: "32px" },
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Calvitin D3.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Candrin 100.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Candrin 200.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/carneo.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/cazit 250.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/cazit 500.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/cfixin.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Clomarin.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Coffrin Dx.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Coffrin.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Etcoxin TH.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Fexrin 120.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Fexrin 180.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Folferin xt.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/glimirin M1.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/glimirin M2.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/glimirin MV1.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/glimirin MV2.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Lulirin.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Montrin LC.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/movirin gel.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Moxyrin CV.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/panrin 40.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/panrin D.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Podorin 200.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Progein 200.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Progein 400.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Raberin 20.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Raberin d.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Raberin L.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Rosurin 10.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Rosurin 40.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Rosurin 5.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Sinarin.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Tenelipt M1000.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Tenelipt M500.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/vibin.svg",
  },
  {
    src: "https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Vitcin D3.svg",
  },
];
