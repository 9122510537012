import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Paper } from "@material-ui/core";
import BlogsIntro from "../Blogs/BlogsIntro";
import axios from "axios";
import { Helmet } from "react-helmet";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export default function Home() {
  //const mediaMatch = window.matchMedia('(min-width: 1300px)');
  //const [matches, setMatches] = useState(mediaMatch.matches);
  const [blogs, setBlogs] = useState([]);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchBlogs = async () => {
      try {
        setBlogs(blogs);
        const response = await axios.get(`${process.env.REACT_APP_API}/blogs`);
        // console.log(response.data);
        setBlogs(response.data);
      } catch (e) {
        // console.log(e);
        setBlogs(blogs);
      }
    };

    fetchBlogs();

    // const handler = e => setMatches(e.matches);
    // mediaMatch.addListener(handler);
    // return () => mediaMatch.removeListener(handler);
  }, []);

  return (
    //<div style={matches ? {background: `url('${process.env.REACT_APP_IMAGES}/carin_pattern2.svg'), url('${process.env.REACT_APP_IMAGES}/carin_pattern2.svg')`, backgroundRepeat: "repeat-y", backgroundPosition: "left, right", backgroundSize: "7.5%, 7.5%"} : {}}>
    <div>
      <Helmet>
        <title>
          CARIN serves a wide range of products across therapies and intends to
          add many more to its portfolio.
        </title>
        <meta
          name="title"
          content="CARIN serves a wide range of products across therapies and intends to add many more to its portfolio."
        />
        <meta
          name="description"
          content="We at CARIN are advocators of Science and using it to advance health is our calling.
									We are an assistive, Digital-First Healthcare brand. We believe that technology can be a great leveler and make Quality Healthcare much more accessible. We strive for a better future, for all."
        />
        <link rel="canonical" href="https://www.carin.life" />
      </Helmet>
      <section className="intro_section page_mainslider">
        <div className="starting text-center">
          <Carousel
            showArrows={true}
            autoPlay
            autoFocus
            dynamicHeight
            infiniteLoop
            interval={"4000"}
          >
            <Paper>
              <img
                src={process.env.REACT_APP_IMAGES + "/landing_1.jpg"}
                alt="First slide"
              />
            </Paper>
            <Paper>
              <img
                src={process.env.REACT_APP_IMAGES + "/landing_2.jpg"}
                alt="Second slide"
              />
            </Paper>
            <Paper>
              <img
                src={process.env.REACT_APP_IMAGES + "/landing_3.jpg"}
                alt="Third slide"
              />
            </Paper>
            <Paper>
              <img
                src={process.env.REACT_APP_IMAGES + "/landing_4.jpg"}
                alt="Fourth slide"
              />
            </Paper>
          </Carousel>
        </div>
      </section>
      {/* <section className="intro_section page_mainslider">
                <div className="flexslider starting text-center landing" data-nav="false">
                    <ul className="slides">
                        <li> <img className="landing-images" src="https://d214gcurai71bm.cloudfront.net/carin-images/LandingPageI_.jpg" alt="" />
                        </li>
                        <li> <img className="landing-images" src="https://d214gcurai71bm.cloudfront.net/carin-images/LandingPageII_.jpg" alt="" />
                        </li>
                        <li> <img className="landing-images" src="https://d214gcurai71bm.cloudfront.net/carin-images/LandingPageIII_.jpg" alt="" />
                        </li>
                    </ul>
                </div>
            </section> */}
      <section
        id="products"
        className="offerings color parallax section_padding_top_130 section_padding_bottom_150 columns_padding_25"
      >
        <div className="container">
          <h2 style={{ textAlign: "center" }} className="section_header">
            Carin Life Products See Our Offerings
          </h2>
          <div className="row display_table_md">
            <div className="col-md-6 col-md-push-6 display_table_cell_md">
              <p
                className="offering"
                style={{ fontSize: "20px", fontWeight: 400 }}
              >
               CARIN serves a wide range of Healthcare Products{" "}
                across therapies and intends to add many more to its portfolio.
                Below are our present offerings.
              </p>
            </div>
            <div className="col-md-6 col-md-pull-6 display_table_cell_md">
              <img
                src={process.env.REACT_APP_IMAGES + "/offerings_common.jpg"}
                alt=""
                className="rounded"
              />
            </div>
          </div>
          <br />
          <div className="row topmargin_30">
            <div className="col-sm-12">
              <div
                className="owl-carousel gallery-carousel"
                data-responsive-lg="5"
                data-responsive-md="4"
                data-responsive-sm="3"
                data-responsive-xs="1"
                data-nav="true"
              >
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    <img
                      src={process.env.REACT_APP_IMAGES + "/gynaecology.jpg"}
                      alt=""
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/gynaecology"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Gynaecology </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={process.env.REACT_APP_IMAGES + "/cardiac.jpg"}
                      alt=""
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/cardiovascular_metabolics"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">
                      Cardiovascular Metabolics{" "}
                    </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={process.env.REACT_APP_IMAGES + "/diabetes.jpg"}
                      alt=""
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/anti-diabetics"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Anti-Diabetics </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={process.env.REACT_APP_IMAGES + "/respiratory.jpg"}
                      alt=""
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/respiratory"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Respiratory </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={process.env.REACT_APP_IMAGES + "/derma.jpg"}
                      alt=""
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/dermatology"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Dermatology </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={process.env.REACT_APP_IMAGES + "/analgesics.jpg"}
                      alt=""
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/analgesics"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Pain/Analgesics </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={
                        process.env.REACT_APP_IMAGES + "/anti_infectives.jpg"
                      }
                      alt=""
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/anti-infectives"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Anti-Infectives </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={process.env.REACT_APP_IMAGES + "/gastro.jpg"}
                      alt=""
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/gastrology"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Gastrology</p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={process.env.REACT_APP_IMAGES + "/vitamins.jpg"}
                      alt=""
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/vitamins"
                        className="abs-link prettyPhoto"
                        title=""
                        data-gal="prettyPhoto[gal]"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Vitamins</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row topmargin_30">
            <div className="col-sm-12">
              <div
                className="owl-carousel gallery-carousel"
                data-responsive-lg="5"
                data-responsive-md="4"
                data-responsive-sm="3"
                data-responsive-xs="1"
                data-nav="true"
              >
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    <img
                      src={process.env.REACT_APP_IMAGES + "/gynaecology.jpg"}
                      alt=""
                      className="offering_img"
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/gynaecology"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Gynaecology </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={process.env.REACT_APP_IMAGES + "/cardiac.jpg"}
                      alt=""
                      className="offering_img"
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/cardiovascular_metabolics"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">
                      Cardiovascular Metabolics{" "}
                    </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={process.env.REACT_APP_IMAGES + "/diabetes.jpg"}
                      alt=""
                      className="offering_img"
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/anti-diabetics"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Anti-Diabetics </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={process.env.REACT_APP_IMAGES + "/respiratory.jpg"}
                      alt=""
                      className="offering_img"
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/respiratory"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Respiratory </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={process.env.REACT_APP_IMAGES + "/derma.jpg"}
                      alt=""
                      className="offering_img"
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/dermatology"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Dermatology </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={process.env.REACT_APP_IMAGES + "/analgesics.jpg"}
                      alt=""
                      className="offering_img"
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/analgesics"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Pain/Analgesics </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={
                        process.env.REACT_APP_IMAGES + "/anti_infectives.jpg"
                      }
                      alt=""
                      className="offering_img"
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/anti-infectives"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Anti-Infectives </p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={process.env.REACT_APP_IMAGES + "/gastro.jpg"}
                      alt=""
                      className="offering_img"
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/gastrology"
                        className="abs-link prettyPhoto"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Gastrology</p>
                  </div>
                </div>
                <div className="gallery-item rounded">
                  <div className="item-media rounded overflow_hidden">
                    {" "}
                    <img
                      src={process.env.REACT_APP_IMAGES + "/vitamins.jpg"}
                      alt=""
                      className="offering_img"
                    />
                    <div className="media-links">
                      {" "}
                      <Link
                        to="/offerings/vitamins"
                        className="abs-link prettyPhoto"
                        title=""
                        data-gal="prettyPhoto[gal]"
                      ></Link>{" "}
                    </div>
                    <p className="offering-titles">Vitamins</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <section
        style={{ padding: "80px 0" }}
        id="features"
        className="columns_margin_bottom_30"
      >
        <div className="container">
          <div className="row why">
            <div
              className="col-md-4 col-sm-12 whyus margin_auto"
              style={{ textAlign: "center" }}
            >
              <h3 className="section_header">
                Why choose Carin life products?
              </h3>{" "}
              <Link
                to="/about"
                className="theme_button color2 min_width_button"
              >
                About Us
              </Link>{" "}
            </div>
            <div className="col-md-4 col-sm-12 whyus margin_auto">
              <div className="teaser media hover_icon">
                <div className="media-left">
                  <div className="teaser_icon rounded main_bg_color size_small">
                    {" "}
                    <i className="rt-icon2-diamond2 color-white"></i>{" "}
                  </div>
                </div>
                <div className="media-body">
                  <h5 style={{ fontWeight: 500 }} className="hover-color2">
                    Excellent Quality
                  </h5>
                  <p>
                   Carin offers premium healthcare products{" "}
                    across therapies
                  </p>
                </div>
              </div>
              <div className="teaser media hover_icon">
                <div className="media-left">
                  <div className="teaser_icon rounded main_bg_color size_small">
                    {" "}
                    <i className="rt-icon2-cloud color-white"></i>{" "}
                  </div>
                </div>
                <div className="media-body">
                  <h5 style={{ fontWeight: 500 }} className="hover-color2">
                    Tech-enabled Services{" "}
                  </h5>
                  <p>
                    {" "}
                    Carin is a digital-first healthcare brand
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 whyus margin_auto">
              <div className="teaser media hover_icon">
                <div className="media-left">
                  <div className="teaser_icon rounded main_bg_color size_small">
                    {" "}
                    <i className="rt-icon2-cloud color-white"></i>{" "}
                  </div>
                </div>
                <div className="media-body">
                  <h5 style={{ fontWeight: 500 }} className="hover-color2">
                    Transparent Information{" "}
                  </h5>
                  <p>
                    {" "}
                    With Carin Healthcare products, You’re No Longer In
                    the Dark, Know What You Consume
                  </p>
                </div>
              </div>
              <div className="teaser media hover_icon">
                <div className="media-left">
                  <div className="teaser_icon rounded main_bg_color size_small">
                    {" "}
                    <i className="rt-icon2-tag2 color-white"></i>{" "}
                  </div>
                </div>
                <div className="media-body">
                  <h5 style={{ fontWeight: 500 }} className="hover-color2">
                    Patient Support
                  </h5>
                  <p>
                    {" "}
                    At Carin, We Care and We're Responsive
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div>
        <div
          className=" to_animate  container about_home"
          style={{ padding: "20px" }}
          data-animation="fadeInLeft"
          data-delay="150"
        >
          <h3 className="logo-color1">
            About Carin - Digital-first healthcare brand
          </h3>
          <ul>
            {" "}
            <li>
              <p>
                Carin is an assistive, Digital-First Healthcare brand. We
                at Carin believe that technology can be a great lever which can
                make Quality Healthcare much more accessible.
              </p>
            </li>
            <li>
              <p>
                With Carin healthcare products, you can be assured of the
                quality.
              </p>
            </li>
            <li>
              <p>Carin strives for a better future, for all.</p>
            </li>
          </ul>
        </div>
      </div>
      <section
        id="features"
        className="ls section_padding_top_150 section_padding_bottom_130 columns_margin_bottom_30"
      >
        <div className="container widget widget_recent_posts ">
          <h2 className="section_header text-center">
            Recent Carin health Blogs
          </h2>
          {blogs.length && (
            <div>
              <BlogsIntro data={blogs[0]} />
              <BlogsIntro data={blogs[1]} />
              <BlogsIntro data={blogs[2]} />
            </div>
          )}
        </div>
      </section>
      <br />

      <section className="cs logo-gradient" style={{ padding: "35px 0" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div
                className="owl-carousel partners-carousel"
                data-responsive-lg="8"
                data-responsive-md="6"
                data-responsive-sm="4"
                data-responsive-xs="2"
                data-nav="true"
                data-dots="false"
                data-center="true"
                data-loop="true"
                style={{ textAlign: "center" }}
              >
                <a href="#!">
                  {" "}
                  <img
                    alt="aceparin SP"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/aceparin SP.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="aceparin"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/aceparin.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Bfolin"
                    style={{ height: "32px" }}
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Bfolin.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Calvitin D3"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Calvitin D3.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Candrin 100"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Candrin 100.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Candrin 200"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Candrin 200.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="carneo"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/carneo.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="cazit 250"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/cazit 250.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="cazit 500"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/cazit 500.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="cfixin"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/cfixin.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Clomarin"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Clomarin.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Coffrin Dx"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Coffrin Dx.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Coffrin"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Coffrin.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Etcoxin TH"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Etcoxin TH.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Fexrin 120"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Fexrin 120.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Fexrin 180"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Fexrin 180.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Folferin xt"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Folferin xt.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="glimirin M1"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/glimirin M1.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="glimirin M2"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/glimirin M2.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="glimirin MV1"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/glimirin MV1.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="glimirin MV2"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/glimirin MV2.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Lulirin"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Lulirin.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Montrin LC"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Montrin LC.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="movirin gel"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/movirin gel.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Moxyrin CV"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Moxyrin CV.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="panrin 40"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/panrin 40.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="panrin D"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/panrin D.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Podorin 200"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Podorin 200.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Progein 200"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Progein 200.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Progein 400"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Progein 400.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Raberin 20"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Raberin 20.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Raberin d"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Raberin d.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Raberin L"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Raberin L.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Rosurin 10"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Rosurin 10.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Rosurin 40"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Rosurin 40.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Rosurin 5"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Rosurin 5.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Sinarin"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Sinarin.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Tenelipt M1000"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Tenelipt M1000.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Tenelipt M500"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Tenelipt M500.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="vibin"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/vibin.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Vitcin D3"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Vitcin D3.svg"
                  />{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
