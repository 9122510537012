import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function Offerings() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          CARIN serves a wide range of products across therapies and intends to
          add many more to its portfolio.
        </title>
        <meta
          name="title"
          content="CARIN serves a wide range of products across therapies and intends to add many more to its portfolio."
        />
        <meta
          name="description"
          content="At Saveo, we are building an innovative B2B single distribution network for pharmacies solving their major problems of managing high SKUs, limited credit & margin, high returns and low customer retention with the use of technology."
        />
        <link rel="canonical" href="https://www.carin.life/offerings" />
      </Helmet>
      <section className="page_breadcrumbs ds color parallax section_padding_top_50 section_padding_bottom_20">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h2 className="logo-color1">OUR OFFERINGS</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="ls section_padding_top_20 section_padding_bottom_100">
        <div className="container">
          <div className="isotope_container isotope row masonry-layout columns_margin_bottom_20">
            <div className="isotope-item col-md-4 col-sm-6">
              <article className="vertical-item content-padding with_border rounded text-center">
                <div className="item-media top_rounded overflow_hidden">
                  {" "}
                  <img
                    src={process.env.REACT_APP_IMAGES + "/gynaecology.jpg"}
                    alt=""
                  />{" "}
                </div>
                <div className="item-content">
                  <h4 className="logo-color1">
                    {" "}
                    <a href="/offerings/gynaecology">Gynaecology</a>{" "}
                  </h4>
                  <p>
                    Women in early pregnancy are susceptible to nutrient
                    deficiencies and likely to develop complications ....
                  </p>
                  <p className="topmargin_20">
                    {" "}
                    <a
                      href="/offerings/gynaecology"
                      className="theme_button color2 inverse min_width_button"
                    >
                      Read More
                    </a>{" "}
                  </p>
                </div>
              </article>
            </div>
            <div className="isotope-item col-md-4 col-sm-6">
              <article className="vertical-item content-padding with_border rounded text-center">
                <div className="item-media top_rounded overflow_hidden">
                  {" "}
                  <img
                    src={process.env.REACT_APP_IMAGES + "/cardiac.jpg"}
                    alt=""
                  />{" "}
                </div>
                <div style={{ padding: "35px 0" }} className="item-content">
                  <h4 style={{ textAlign: "center" }} className="logo-color1">
                    {" "}
                    <a href="/offerings/cardiovascular_metabolics">
                      Cardiovascular Metabolics
                    </a>{" "}
                  </h4>
                  <p style={{ padding: "0 35px" }}>
                    Hypertension is responsible for over 10 million deaths
                    annually and it affect one in four adults globally....
                  </p>
                  <p style={{ padding: "0 35px" }} className="topmargin_20">
                    {" "}
                    <a
                      href="/offerings/cardiovascular_metabolics"
                      className="theme_button color2 inverse min_width_button"
                    >
                      Read More
                    </a>{" "}
                  </p>
                </div>
              </article>
            </div>
            <div className="isotope-item col-md-4 col-sm-6">
              <article className="vertical-item content-padding with_border rounded text-center">
                <div className="item-media top_rounded overflow_hidden">
                  {" "}
                  <img
                    src={process.env.REACT_APP_IMAGES + "/diabetes.jpg"}
                    alt=""
                  />{" "}
                </div>
                <div className="item-content">
                  <h4 className="logo-color1">
                    {" "}
                    <a href="/offerings/anti-diabetics">Anti-Diabetics</a>{" "}
                  </h4>
                  <p>
                    Diabetes is a metabolic disease characterized by high blood
                    glucose due to insulin deficiency....
                  </p>
                  <p className="topmargin_20">
                    {" "}
                    <a
                      href="/offerings/anti-diabetics"
                      className="theme_button color2 inverse min_width_button"
                    >
                      Read More
                    </a>{" "}
                  </p>
                </div>
              </article>
            </div>
            <div className="isotope-item col-md-4 col-sm-6">
              <article className="vertical-item content-padding with_border rounded text-center">
                <div className="item-media top_rounded overflow_hidden">
                  {" "}
                  <img
                    src={process.env.REACT_APP_IMAGES + "/respiratory.jpg"}
                    alt=""
                  />{" "}
                </div>
                <div className="item-content">
                  <h4 className="logo-color1">
                    {" "}
                    <a href="/offerings/respiratory">Respiratory</a>{" "}
                  </h4>
                  <p>
                    Respiratory diseases are diseases of the airways and other
                    parts of the lung, and are among the leading causes...
                  </p>
                  <p className="topmargin_20">
                    {" "}
                    <a
                      href="/offerings/respiratory"
                      className="theme_button color2 inverse min_width_button"
                    >
                      Read More
                    </a>{" "}
                  </p>
                </div>
              </article>
            </div>
            <div className="isotope-item col-md-4 col-sm-6">
              <article className="vertical-item content-padding with_border rounded text-center">
                <div className="item-media top_rounded overflow_hidden">
                  {" "}
                  <img
                    src={process.env.REACT_APP_IMAGES + "/derma.jpg"}
                    alt=""
                  />{" "}
                </div>
                <div className="item-content">
                  <h4 className="logo-color1">
                    {" "}
                    <a href="/offerings/dermatology">Dermatology</a>{" "}
                  </h4>
                  <p>
                    Skin diseases are among the most common diseases in the
                    general population and are mainly caused...
                  </p>
                  <p className="topmargin_20">
                    {" "}
                    <a
                      href="/offerings/dermatology"
                      className="theme_button color2 inverse min_width_button"
                    >
                      Read More
                    </a>{" "}
                  </p>
                </div>
              </article>
            </div>
            <div className="isotope-item col-md-4 col-sm-6">
              <article className="vertical-item content-padding with_border rounded text-center">
                <div className="item-media top_rounded overflow_hidden">
                  {" "}
                  <img
                    src={process.env.REACT_APP_IMAGES + "/analgesics.jpg"}
                    alt=""
                  />{" "}
                </div>
                <div className="item-content">
                  <h4 className="logo-color1">
                    {" "}
                    <a href="/offerings/analgesics">Pain/Analgesics</a>{" "}
                  </h4>
                  <p>
                    International Association for the Study of Pain defined
                    chronic pain as the pain that persists beyond the normal...
                  </p>
                  <p className="topmargin_20">
                    {" "}
                    <a
                      href="/offerings/analgesics"
                      className="theme_button color2 inverse min_width_button"
                    >
                      Read More
                    </a>{" "}
                  </p>
                </div>
              </article>
            </div>
            <div className="isotope-item col-md-4 col-sm-6">
              <article className="vertical-item content-padding with_border rounded text-center">
                <div className="item-media top_rounded overflow_hidden">
                  {" "}
                  <img
                    src={process.env.REACT_APP_IMAGES + "/anti_infectives.jpg"}
                    alt=""
                  />{" "}
                </div>
                <div className="item-content">
                  <h4 className="logo-color1">
                    {" "}
                    <a href="/offerings/anti-infectives">
                      Anti-Infectives
                    </a>{" "}
                  </h4>
                  <p>
                    An infection occurs when microorganisms such as bacteria,
                    fungi, viruses and parasites enter into...
                  </p>
                  <p className="topmargin_20">
                    {" "}
                    <a
                      href="/offerings/anti-infectives"
                      className="theme_button color2 inverse min_width_button"
                    >
                      Read More
                    </a>{" "}
                  </p>
                </div>
              </article>
            </div>
            <div className="isotope-item col-md-4 col-sm-6">
              <article className="vertical-item content-padding with_border rounded text-center">
                <div className="item-media top_rounded overflow_hidden">
                  {" "}
                  <img
                    src={process.env.REACT_APP_IMAGES + "/gastro.jpg"}
                    alt=""
                  />{" "}
                </div>
                <div className="item-content">
                  <h4 className="logo-color1">
                    {" "}
                    <a href="/offerings/gastrology">Gastrology</a>{" "}
                  </h4>
                  <p>
                    Gastroesophageal reflux disease is a chronic disease in
                    which the acidified liquid contents of the stomach....
                  </p>
                  <p className="topmargin_20">
                    {" "}
                    <a
                      href="/offerings/gastrology"
                      className="theme_button color2 inverse min_width_button"
                    >
                      Read More
                    </a>{" "}
                  </p>
                </div>
              </article>
            </div>
            <div className="isotope-item col-md-4 col-sm-6">
              <article className="vertical-item content-padding with_border rounded text-center">
                <div className="item-media top_rounded overflow_hidden">
                  {" "}
                  <img
                    src={process.env.REACT_APP_IMAGES + "/vitamins.jpg"}
                    alt=""
                  />{" "}
                </div>
                <div className="item-content">
                  <h4 className="logo-color1">
                    {" "}
                    <a href="/offerings/vitamins">Vitamins</a>{" "}
                  </h4>
                  <p>
                    Multivitamins and minerals supplements help people who don’t
                    get enough vitamins and minerals from the diet...
                  </p>
                  <p className="topmargin_20">
                    {" "}
                    <a
                      href="/offerings/vitamins"
                      className="theme_button color2 inverse min_width_button"
                    >
                      Read More
                    </a>{" "}
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
