import React, { useEffect, useState } from "react";
import OfferingsCarousel from "./OfferingsCarousel";
import OfferingsDisplay from "./OfferingsDisplay";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet";

export default function Cardiovascular() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [offerings, setOfferings] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchOfferings = async () => {
      try {
        setOfferings(offerings);
        const response = await axios.get(
          `${process.env.REACT_APP_API}/offerings/cardiovascular`
        );
        setOfferings(response.data);
      } catch (e) {
        console.log(e);
        setOfferings(offerings);
      }
    };

    fetchOfferings();
  }, []);

  return (
    <div>
     <Helmet>
         <title>Healthcare Products for Cardiovascular Diseases (CVDs) - CARIN Life </title>
        <meta name="title" content="Healthcare Products for Cardiovascular Diseases (CVDs) - CARIN Life" />
        <meta name="description" content="Cardiovascular diseases (CVDs) are diseases of the heart and blood vessels and they include mainly heart attacks. Checkout products offered by CARIN Life for Cardiovascular diseases." />
        </Helmet>
      <section className="ls section_padding_top_25">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {" "}
              <img
                src={process.env.REACT_APP_IMAGES + "/cardiac.jpg"}
                alt=""
                className="alignleft offering_img"
              />
              <div
                className="to_animate section_padding_top_150"
                data-animation="fadeInLeft"
                data-delay="150"
              >
                <h3 className="section_header">Cardiovascular diseases</h3>
                <p>
                  Cardiovascular diseases (CVDs) are a group of diseases of the
                  heart and blood vessels and they include mainly heart attacks
                  and strokes. Heart attacks and strokes are primarily caused by
                  a blockage that prevents normal blood flow into the heart or
                  brain. The main risk factors associated with heart attacks and
                  strokes are hypertension (high blood pressure),
                  hyperlipidaemia (high cholesterol), diabetes (high sugar),
                  smoking, drinking alcohol, unhealthy diet etc.
                </p>
              </div>
            </div>
            <br />
            <div
              className="col-sm-12 to_animate"
              data-animation="fadeInLeft"
              data-delay="150"
            >
              <h4 className="logo-color1">Hypertension:</h4>
              <p>
                Hypertension or high blood pressure (BP) is responsible for over
                10 million deaths annually and it affect one in four adults
                globally. From India, District Level Household Survey (DLHS)
                reported that 27.4% of the male and 20.0% of the female were
                affected by hypertension (overall 25.3%) [1]. Hypertension if
                left untreated may cause life-threatening diseases like coronary
                artery disease (CAD), heart attack, stroke, and kidney failure.
              </p>
            </div>
          </div>
          <br />
          <div className="row">
            <div
              className="col-sm-12 to_animate"
              data-animation="fadeInLeft"
              data-delay="150"
            >
              <h3 className="logo-color1 product_header">Products of Carin:</h3>
            </div>
          </div>
        </div>
      </section>
      <section
        id="products"
        className="offerings ds color parallax page_gallery columns_padding_25 to_animate"
        data-animation="fadeInLeft"
        data-delay="150"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Carousel
                showDots={false}
                responsive={responsive}
                transitionDuration={500}
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
              >
                {offerings.map((offering) => (
                  <OfferingsCarousel data={offering} />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
      <section className="ls section_padding_bottom_50">
        <div className="container">
          <div className="row">
            {offerings.length &&
              offerings.map((offering, index) => {
                return <OfferingsDisplay data={offering} idx={index + 1} />;
              })}
          </div>
        </div>
      </section>

      <section className="ls section_padding_bottom_75">
        <div className="container">
          <div className="row">
            <div
              className="to_animate"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <table className="card-table table table-striped diabetes-table col-sm-12">
                <thead className="cardiac-table-color">
                  <tr>
                    <th className="color-red" colspan="3">
                      BLOOD PRESSURE CATEGORIES
                    </th>
                  </tr>
                  <tr>
                    <th colspan="3" className="color-grey">
                      According to the guidelines of the American Heart
                      Association (AHA)
                    </th>
                  </tr>
                </thead>
                <tbody className="cardiac-tbody">
                  <tr className="logo-color1 cardiac-table-color">
                    <td>Status</td>
                    <td>
                      Systolic blood pressure <br />
                      <span className="color-orange">(mm Hg)</span>
                    </td>
                    <td>
                      Diastolic blood pressure <br />
                      <span className="color-orange">(mm Hg)</span>
                    </td>
                  </tr>
                  <tr className="tb-normal">
                    <td>1. Normal</td>
                    <td>Less than 120</td>
                    <td>Less than 80</td>
                  </tr>
                  <tr className="tb-elevated">
                    <td>2. Elevated</td>
                    <td>120-129</td>
                    <td>Less than 80</td>
                  </tr>
                  <tr className="tb-stage1">
                    <td>
                      3. Hypertension <br />
                      (Stage-1)
                    </td>
                    <td>130-139</td>
                    <td>80-89</td>
                  </tr>
                  <tr className="tb-stage2">
                    <td>
                      4. Hypertension <br />
                      (Stage-2)
                    </td>
                    <td>140-180</td>
                    <td>90-120</td>
                  </tr>
                  <tr className="tb-stage3">
                    <td>
                      5. Hypertension <br />
                      Crisis
                    </td>
                    <td>Above 180</td>
                    <td>Above 120</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              style={{ paddingTop: "30px" }}
              className="col-sm-12 to_animate"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <h4 style={{ lineHeight: "32px" }} className="logo-color1">
                Management and treatment of hypertension:
              </h4>
              <h5 className="txt-bold logo-color2">Lifestyle adjustments:</h5>
              <p>
                Changing lifestyle like intake of a healthy diet, regular
                physical activities, limiting extra salts, restraining drinking
                alcohol etc might help to control high blood pressure.
              </p>
              <h5 className="txt-bold logo-color2">Medications:</h5>
              <p>
                However, if lifestyle adjustments aren't enough for the
                management of hypertension then doctor may recommend medications
                to control high blood pressure.
              </p>
              <br />
            </div>

            <div
              className="col-sm-12 to_animate"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <h4 className="logo-color1">Major take way:</h4>
              <p>
                Good tolerability and flexible dosing profile of Telmisartan
                with additional long-lasting effect make it more effective
                antihypertensive drug then other class of antihypertensive
                drugs. Combined therapy of amlodipine and telmisartan further
                reduced BP with a better safety profile. Rosuvastatin Calcium
                also prevents cardiovascular disease by lowering bad (LDL)
                cholesterol.
              </p>
            </div>

            <div
              style={{ paddingTop: "30px" }}
              className="col-sm-12 to_animate"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <div id="accordion3" className="panel-group collapse-unstyled">
                <div className="panel">
                  <h4 className="poppins logo-color1">
                    {" "}
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#collapse11"
                    >
                      References:
                    </a>{" "}
                  </h4>
                  <div id="collapse11" className="panel-collapse collapse">
                    <div className="panel-content">
                      {" "}
                      <ol className="list1 no-bullets">
                        <li>
                          Gupta R, Gaur K, Ram CVS. Emerging trends in
                          hypertension epidemiology in India. Journal of human
                          hypertension. 2019;33(8):575-87.
                        </li>
                        <li>
                          Asmar R. Telmisartan in high cardiovascular risk
                          patients. Eur Cardiol Rev. 2012;8(1):10-6.
                        </li>
                        <li>
                          Ostroumova OD, Smolyarchuk EA, Bondarec OV, Paleeva
                          EE. Clinical Advantages of Telmisartan: Features of
                          Pharmacokinetics, Pharmacodynamics and Effects on the
                          Daily Blood Pressure Profile. Rational Pharmacotherapy
                          in Cardiology. 2017;13(4):550-7.
                        </li>
                        <li>
                          Dézsi CA. The different therapeutic choices with ARBs.
                          Which one to give? When? Why? American Journal of
                          Cardiovascular Drugs. 2016;16(4):255-66.
                        </li>
                        <li>
                          Ohishi M, Kawai T, Hayashi N, Kitano S, Katsuya T,
                          Nagano M, et al. Effect of tablets with a combination
                          of telmisartan and amlodipine on patients with
                          hypertension: the Cotalo study. Hypertension Research.
                          2013;36(7):620-6.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
