import React, { useEffect, useState } from "react";
import OfferingsCarousel from "./OfferingsCarousel";
import OfferingsDisplay from "./OfferingsDisplay";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet";

export default function Respiratory() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [offerings, setOfferings] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchOfferings = async () => {
      try {
        setOfferings(offerings);
        const response = await axios.get(
          `${process.env.REACT_APP_API}/offerings/respiratory`
        );
        setOfferings(response.data);
      } catch (e) {
        console.log(e);
        setOfferings(offerings);
      }
    };

    fetchOfferings();
  }, []);

  return (
    <div>
    <Helmet>
         <title>Healthcare Products for Respiratory Diseases - CARIN Life </title>
        <meta name="title" content="Healthcare Products for Respiratory Diseases - CARIN Life" />
        <meta name="description" content="Chronic respiratory diseases like asthma, pneumonia are among the leading causes of mortality and morbidity globally. Checkout Respiratory diseases products by CARIN Life." />
        </Helmet>
      <section className="ls section_padding_top_25">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {" "}
              <img
                src={process.env.REACT_APP_IMAGES + "/respiratory.jpg"}
                alt=""
                className="alignleft offering_img"
              />
              <div
                className="to_animate section_padding_top_150"
                data-animation="fadeInLeft"
                data-delay="150"
              >
                <h3 className="section_header">Respiratory Diseases</h3>
                <p>
                  Chronic respiratory diseases are diseases of the airways and
                  other parts of the lung, and are among the leading causes of
                  mortality and morbidity globally. Respiratory diseases include
                  asthma, chronic obstructive pulmonary disease (COPD),
                  pneumonia, pulmonary fibrosis, and other occupational lung
                  diseases [1]. Common symptoms of chronic respiratory diseases
                  are cough, Wheezing, Difficulty Breathing, fever, nasal
                  congestion etc. Globally, around 65 million people suffer from
                  COPD and about 334 million people suffer from asthma. About 4
                  million people die prematurely from chronic respiratory
                  disease worldwide [2]. In India, COPD and asthma were
                  responsible for 75·6% and 20% of the respiratory disease
                  respectively [3]
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div
              className="col-sm-12 to_animate"
              data-animation="fadeInLeft"
              data-delay="150"
            >
              <h3
                style={{ marginBottom: 0, paddingBottom: 0 }}
                className="logo-color1"
              >
                Products of Carin:
              </h3>
            </div>
          </div>
        </div>
      </section>

      <section
        id="products"
        className="offerings ds color parallax page_gallery columns_padding_25 to_animate"
        data-animation="fadeInLeft"
        data-delay="150"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Carousel
                showDots={false}
                responsive={responsive}
                transitionDuration={500}
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
                s
              >
                {offerings.map((offering) => (
                  <OfferingsCarousel data={offering} />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>

      <section className="ls">
        <div className="container">
          <div className="row">
            {offerings.length &&
              offerings.map((offering, index) => {
                return <OfferingsDisplay data={offering} idx={index + 1} />;
              })}
            <div
              className="col-sm-12 to_animate"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <br />
              <h4 className="logo-color1">
                Management of respiratory diseases:
              </h4>
              <p>
                Appropriate management of each chronic respiratory disease
                differs and thus risk factors reduction and avoidance and proper
                medication are the ideal management for each chronic respiratory
                disease.
              </p>
            </div>
            <div
              style={{ padding: "30px 0 70px 10px" }}
              className="col-sm-12 to_animate"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <div id="accordion3" className="panel-group collapse-unstyled">
                <div className="panel">
                  <h4 className="poppins logo-color1">
                    {" "}
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#collapse11"
                    >
                      References:
                    </a>
                  </h4>
                  <div id="collapse11" className="panel-collapse collapse">
                    <div className="panel-content">
                      {" "}
                      <ol className="list1 no-bullets">
                        <li>
                          Soriano JB, Kendrick PJ, Paulson KR, Gupta V, Abrams
                          EM, Adedoyin RA, et al. Prevalence and attributable
                          health burden of chronic respiratory diseases,
                          1990–2017: a systematic analysis for the Global Burden
                          of Disease Study 2017. The Lancet Respiratory
                          Medicine. 2020;8(6):585-96.
                        </li>
                        <li>
                          {" "}
                          Societies FoIR. The global impact of respiratory
                          disease. Second ed: European Respiratory Society;
                          2017.
                        </li>
                        <li>
                          Salvi S, Kumar GA, Dhaliwal R, Paulson K, Agrawal A,
                          Koul PA, et al. The burden of chronic respiratory
                          diseases and their heterogeneity across the states of
                          India: the Global Burden of Disease Study 1990–2016.
                          The Lancet Global Health. 2018;6(12):e1363-e74.
                        </li>
                        <li>
                          Adsule S, Misra D. Long term treatment with
                          montelukast and levocetirizine combination in
                          persistent allergic rhinitis: review of recent
                          evidence. Journal of the Indian Medical Association.
                          2010;108(6):381-2.
                        </li>
                        <li>
                          Kiran M, Pawaskar L. Safety and efficacy for the
                          combination of chlorpheniramine maleate and
                          phenylephrine in the symptomatic treatment of allergic
                          rhinitis in paediatric population: phase IV clinical
                          trial. World Journal of Pharmaceutical and Medical
                          Research. 2019;5(2):232-6.
                        </li>
                        <li>
                          Axelrod D, Bielory L. Fexofenadine hydrochloride in
                          the treatment of allergic disease: a review. Journal
                          of asthma and allergy. 2008;1:19.
                        </li>
                        <li>
                          Ciplamed. AXALIN Expectorant (Ambroxol hydrochloride +
                          Terbutaline sulphate + Guaiphenesin).
                          <br /> Available from:{" "}
                          <a href=" https://www.ciplamed.com/content/axalin-expectorant">
                            {" "}
                            https://www.ciplamed.com/content/axalin-expectorant.
                          </a>
                        </li>
                        <li>
                          Available from:{" "}
                          <a href=" https://www.medicoverhospitals.in/medicine/grilinctus">
                            {" "}
                            https://www.medicoverhospitals.in/medicine/grilinctus.
                          </a>
                        </li>
                      </ol>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
