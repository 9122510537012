import React, { useEffect, useState } from "react";
import OfferingsCarousel from "./OfferingsCarousel";
import OfferingsDisplay from "./OfferingsDisplay";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet";

export default function Infectives() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [offerings, setOfferings] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchOfferings = async () => {
      try {
        setOfferings(offerings);
        const response = await axios.get(
          `${process.env.REACT_APP_API}/offerings/infectives`
        );
        setOfferings(response.data);
      } catch (e) {
        console.log(e);
        setOfferings(offerings);
      }
    };

    fetchOfferings();
  }, []);

  return (
    <div>
     <Helmet>
         <title>Healthcare Products for Bacteria/Virus Infections - CARIN Life </title>
        <meta name="title" content="Healthcare Products for Bacteria/Virus Infections - CARIN Life" />
        <meta name="description" content="An infection occurs when microorganisms such as bacteria, fungi, viruses and parasites enter into the body and cause disease. Checkout products offered by CARIN Life for such bacteria infections." />
        </Helmet>
      <section className="ls section_padding_top_25">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {" "}
              <img
                src={process.env.REACT_APP_IMAGES + "/anti_infectives.jpg"}
                alt=""
                className="alignleft offering_img"
              />
              <div
                className="to_animate section_padding_top_100"
                data-animation="fadeInLeft"
                data-delay="150"
              >
                <h3 className="section_header">Infection and Antibiotics</h3>
                <p>
                  An infection occurs when microorganisms such as bacteria,
                  fungi, viruses and parasites enter into the body and cause
                  disease. Common symptoms of infection are fever, cough, sore
                  throat, nasal congestion, inflammation, shortness of breath,
                  etc. According to the Centers of Disease Control and
                  Prevention (CDC) infectious diseases account for about 15.5
                  million doctor’s visits per year. Microorganism like
                  Pseudomonas aeruginosa, Acinetobacter baumannii, Klebsiella
                  pneumoniae, Escherichia coli, Pseudomonas aeruginosa etc. were
                  commonly associated with the bacterial infection [1].
                </p>
              </div>
            </div>
            <div
              className="col-sm-12 to_animate"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <h3 className="logo-color1 product_header">Products of Carin:</h3>
            </div>
          </div>
        </div>
      </section>

      <section
        id="products"
        className="offerings ds color parallax page_gallery columns_padding_25 to_animate"
        data-animation="fadeInLeft"
        data-delay="150"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Carousel
                showDots={false}
                responsive={responsive}
                transitionDuration={500}
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
              >
                {offerings.map((offering) => (
                  <OfferingsCarousel data={offering} />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>

      <section className="ls section_padding_bottom_65">
        <div className="container">
          <div className="row">
            {offerings.length &&
              offerings.map((offering, index) => {
                return <OfferingsDisplay data={offering} idx={index + 1} />;
              })}
            <div
              className="col-sm-12 to_animate topmargin_25"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <br />
              <h4 className="logo-color1">
                Management of infections with antibiotics:
              </h4>
              <p>
                Treatment of the infection depends on the type of microorganism
                that causes infection. Antibiotics are a group of drugs that are
                used to treat bacterial and certain parasite infections.
                However, antibiotics are not used to treat viral infections.
                Bacteria may develop resistance against antibiotics and reduce
                the efficiency of drugs; therefore, proper use of antibiotics is
                very important to prevent antibiotic resistance.
              </p>
            </div>
            <div
              className="col-sm-12 to_animate topmargin_30"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <div id="accordion3" className="panel-group collapse-unstyled">
                <div className="panel">
                  <br />
                  <h4 className="poppins logo-color1">
                    {" "}
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#collapse11"
                    >
                      References:
                    </a>{" "}
                  </h4>
                  <div id="collapse11" className="panel-collapse collapse">
                    <div className="panel-content">
                      {" "}
                      <ol className="list1 no-bullets">
                        <li>
                          Ghanshani R, Gupta R, Gupta BS, Kalra S, Khedar RS,
                          Sood S. Epidemiological study of prevalence,
                          determinants, and outcomes of infections in medical
                          ICU at a tertiary care hospital in India. Lung India:
                          official organ of Indian Chest Society.
                          2015;32(5):441.
                        </li>
                        <li>
                          Accessdata.fda.gov. Amoxicillin/Clavulanate Potassium
                          Tablets 2008. Available from:
                          https://www.accessdata.fda.gov/drugsatfda_docs/label/2008/050564s049,050720s022lbl.pdf.
                        </li>
                        <li>
                          Parnham MJ, Haber VE, Giamarellos-Bourboulis EJ,
                          Perletti G, Verleden GM, Vos R. Azithromycin:
                          mechanisms of action and their relevance for clinical
                          applications. Pharmacology & therapeutics.
                          2014;143(2):225-45.
                        </li>
                        <li>
                          Accessdata.fda.gov. Cefpodoxime Tabs - FDA2014.
                          Available from:
                          https://www.accessdata.fda.gov/drugsatfda_docs/label/2014/065462s005lbl.pdf.
                        </li>
                        <li>
                          Tan BJ. Cefixime use in children: When and why.
                          Canadian Journal of Infectious Diseases.
                          1995;6(4):204-5.
                        </li>
                      </ol>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
